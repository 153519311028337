import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useContext, useEffect, useRef } from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { Link as RLink, useLocation, useNavigate } from 'react-router-dom';

import { Select } from 'chakra-react-select';
import { BsFillHouseDoorFill, BsBookHalf } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../providers/AuthProvider';
import { useTours } from '../../providers/tours';
import { useGetEnterprisesQuery } from '../../redux/api/accountApi';
import {
  setIsNavBarMenuOpen,
  setSelectedEnterprise,
} from '../../redux/slices/authSlice';
import { setShow } from '../../redux/slices/cartSlice';
import IconBadge from '../IconBadge';
import NotificationTray from './NotificationTray';
import { ACCOUNT_TYPE_VALUES } from '../../utils';
import _ from 'lodash';

const AuthUserMenu = ({ user, handleLogout, userType }) => {
  const dispatch = useDispatch();
  const { isEnterprise, isUnlimitedEnterprise } = useContext(AuthContext);
  const menuRef = useRef(null);
  const tours = useTours();
  const { isNavBarMenuOpen } = useSelector(state => state.auth);

  const handleOutsideClick = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      dispatch(setIsNavBarMenuOpen(false));
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, []);

  return (
    <Box ref={menuRef}>
      <Menu
        isOpen={isNavBarMenuOpen}
        onClick={() => {
          dispatch(setIsNavBarMenuOpen(!isNavBarMenuOpen));
        }}
      >
        <MenuButton
          as={Button}
          variant={'flat'}
          cursor={'pointer'}
          minW={0}
          rightIcon={<ChevronDownIcon />}
          color="white"
          onClick={() => {
            dispatch(setIsNavBarMenuOpen(!isNavBarMenuOpen));
            tours.handleGoNext();
          }}
        >
          {user?.name}
        </MenuButton>
        <MenuList
          alignItems={'center'}
          onClick={() => dispatch(setIsNavBarMenuOpen(!isNavBarMenuOpen))}
        >
          <RLink to={'/profile'}>
            <MenuItem fontFamily="label" data-tour="nav-bar-2">
              Edit Profile
            </MenuItem>
          </RLink>
          <RLink to={`/request-board-design?user=${user?._id}`}>
            <MenuItem fontFamily="label">My Request Board Posts</MenuItem>
          </RLink>
          {userType === 'tech' && (
            <RLink to={'credit-cards'} data-tour="nav-bar-3">
              <MenuItem fontFamily="label">Credit Cards</MenuItem>
            </RLink>
          )}
          {userType === 'expert' && (
            <RLink to={'withdrawal-method'} data-tour="nav-bar-3">
              <MenuItem fontFamily="label">Withdrawals</MenuItem>
            </RLink>
          )}
          {(isEnterprise || isUnlimitedEnterprise) && (
            <RLink to={'/admin/dashboard'} data-tour="enterprise-admin">
              <MenuItem fontFamily="label">Enterprise Admin</MenuItem>
            </RLink>
          )}
          {user?.type?.includes('sysadmin') && (
            <RLink to={'/sysadmin/dashboard'}>
              <MenuItem fontFamily="label">Sys Admin</MenuItem>
            </RLink>
          )}
          <RLink to={'/onboard'}>
            <MenuItem fontFamily="label">Training Library</MenuItem>
          </RLink>
          <MenuItem fontFamily="label" color="pink.500" onClick={handleLogout}>
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const EnterpriseSelector = () => {
  const dispatch = useDispatch();
  const authState = useSelector(store => store?.auth);
  const { data: enterprises, isLoading } = useGetEnterprisesQuery({
    pageSize: 10000,
  });

  const enterpriseOptions = enterprises?.data?.map(item => ({
    value: item._id,
    label: `cname: ${item.company_name} name: ${item.name} userId: ${
      item.ubimaxId
    } ${item._id === authState.user?._id ? '(default)' : ''}`,
    id: item._id,
    _id: item._id,
  }));

  const handleReset = () => {
    const currentEnterprise = enterprises?.data?.find(
      d => d._id === authState.user?._id
    );

    dispatch(
      setSelectedEnterprise({
        value: currentEnterprise._id,
        label: `cname: ${currentEnterprise.company_name} name: ${currentEnterprise.name} userId: ${currentEnterprise.ubimaxId} (default)`,
        id: currentEnterprise._id,
        _id: currentEnterprise._id,
      })
    );
  };

  const onEnterpriseChange = e => {
    if (!e) {
      // reset
      handleReset();
    } else {
      dispatch(setSelectedEnterprise(e));
    }
  };

  return (
    <Box
      w={'sm'}
      position={'relative'}
      backgroundColor={'white'}
      borderRadius={8}
    >
      <Select
        w="full"
        size="md"
        options={enterpriseOptions}
        value={authState.selectedEnterprise}
        isLoading={isLoading}
        onChange={onEnterpriseChange}
        isClearable={true}
      />
    </Box>
  );
};

const Nav = () => {
  const {
    authState,
    cartState,
    isAuthenticated,
    handleLogout,
    isSysAdmin,
    onProfileRoleChange,
  } = useContext(AuthContext);
  const { show: showCart } = useSelector(state => state.cart);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isTechAndExpert =
    _.intersection(
      [ACCOUNT_TYPE_VALUES.EXPERT, ACCOUNT_TYPE_VALUES.TECH],
      authState?.user?.type
    )?.length === 2;

  const handleRoleChange = evt => {
    onProfileRoleChange(evt.target.checked ? 'expert' : 'tech');
  };

  const getUserNavbarSubtitle = () => {
    const planName =
      authState?.user?.subscription?.product?.name ||
      authState?.user?.parent?.subscription?.product?.name;
    const companyName =
      authState?.user?.company_name ||
      authState?.user?.parent?.company_name ||
      authState?.user?.name ||
      authState?.user?.parent?.name;
    if (planName) return `${planName} (${companyName})`;
    return '';
  };

  return (
    <Box bgGradient="linear(to-r, #016DB8, #17375F)" px={4}>
      <Flex h={24} alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          <RLink
            onClick={() => {
              if (
                !(
                  isAuthenticated &&
                  !location.pathname.includes('login') &&
                  !location.pathname.includes('signup')
                )
              )
                window.location.href = 'https://newforge.pro';
            }}
            to={
              isAuthenticated &&
              !location.pathname.includes('login') &&
              !location.pathname.includes('signup') &&
              '/dashboard'
            }
          >
            <Image
              src="/telup/logo192.png"
              alt="NEWFORGE"
              objectFit="contain"
              htmlWidth={250}
            />
          </RLink>
        </Box>

        <Flex alignItems={'center'}>
          {!isAuthenticated && (
            <RLink to="/marketplace">
              <Text color="white" fontWeight="bold" fontFamily="label">
                Shop
              </Text>
            </RLink>
          )}
          {isAuthenticated && isSysAdmin && <EnterpriseSelector />}
          {isAuthenticated && getUserNavbarSubtitle() && (
            <HStack p={3}>
              <Badge colorScheme={'orange'}>
                {/* {authState?.user?.subscription?.product?.name} */}
                <Box as="span"> {getUserNavbarSubtitle()}</Box>
              </Badge>
            </HStack>
          )}

          {isAuthenticated && isTechAndExpert && (
            <HStack spacing={4} py={3}>
              <Text color="white" fontWeight="bold">
                Technician
              </Text>
              <Switch
                colorScheme="blackAlpha"
                size="lg"
                value="expert"
                onChange={handleRoleChange}
                defaultChecked={authState?.defaultProfileRole === 'expert'}
              />
              <Text color="white" fontWeight="bold">
                Expert
              </Text>
            </HStack>
          )}

          <Stack direction={'row'} spacing={7} data-tour="nav-bar">
            {isAuthenticated && (
              <AuthUserMenu
                handleLogout={handleLogout}
                user={authState?.user}
                userType={authState?.defaultProfileRole}
              />
            )}
          </Stack>
          <RLink to="/dashboard">
            <IconBadge icon={BsFillHouseDoorFill} />
          </RLink>
          <RLink to="/onboard">
            <IconBadge icon={BsBookHalf} />
          </RLink>
          <RLink
            {...(!location.pathname.includes('marketplace') && {
              to: 'marketplace',
            })}
            onClick={() => dispatch(setShow(!showCart))}
          >
            <IconBadge
              count={cartState?.totalQuantity}
              icon={AiOutlineShoppingCart}
            />
          </RLink>

          {isAuthenticated && <NotificationTray />}
        </Flex>
      </Flex>
    </Box>
  );
};

export default function NavTour() {
  return <Nav />;
}
