import { Stack, HStack, Text, Link, Image, Flex } from '@chakra-ui/react';
import FaceBook from './imgs/facebook.png';
import Instagram from './imgs/instagram.png';
import LinkedIn from './imgs/linkedin.png';

const Footer = () => {
  return (
    <Stack alignItems="center" py={2} borderTop="1px solid #DFDFDF">
      <Flex py={0} my={0}>
        <Link href="https://www.facebook.com/newforgetech" target="_blank">
          <Image
            fallbackSrc="https://www.facebook.com/newforgetech"
            src={FaceBook}
            boxSize="50px"
            objectFit="cover"
          />
        </Link>
        <Link href="https://www.instagram.com/newforgetech/" target="_blank">
          <Image
            href="https://www.instagram.com/newforgetech/"
            src={Instagram}
            boxSize="50px"
            objectFit="cover"
          />
        </Link>
        <Link
          href="https://www.linkedin.com/company/newforgetech/"
          target="_blank"
        >
          <Image
            fallbackSrc="https://www.linkedin.com/company/newforgetech/"
            src={LinkedIn}
            boxSize="50px"
            objectFit="cover"
          />
        </Link>
      </Flex>
      <Text as="b">NEWFORGE Knowledge Place</Text>
      <Text as="p" fontFamily="label">
        54 Marina Drive Suite 205 Lake Wylie, South Carolina 29710
      </Text>
      <HStack spacing={1} w="100%" justifyContent={'center'}>
        <Link
          color="#17365E"
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
          fontWeight="bold"
        >
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </Link>
        <Link
          data-tour="my-appointments-9"
          position={'absolute'}
          right="10px"
          color="#17365E"
          href="https://forms.gle/V4YH573oQeyzjf8K7"
          target={'_blank'}
          fontWeight="bold"
        >
          Feedback?
        </Link>
      </HStack>
      <Text>
        © Copyrights 2023 newforge.pro | All rights reserved | v
        {process.env.REACT_APP_VERSION}
      </Text>
    </Stack>
  );
};

export default Footer;